import { useMemo } from 'react';
import orderBy from 'lodash/orderBy';

const languageNames = {
  en: 'English',
  hi: 'Hindi',
  ar: 'Arabic',
  bn: 'Bengali',
  de: 'German',
  et: 'Estonian',
  fr: 'French',
  gu: 'Gujarati',
  it: 'Italian',
  ja: 'Japanese',
  kn: 'Kannada',
  ml: 'Malayalam',
  mr: 'Marathi',
  ne: 'Nepali',
  nl: 'Dutch',
  pa: 'Punjabi',
  pt: 'Portuguese',
  te: 'Telugu',
  tl: 'Tagalog',
  ur: 'Urdu',
  other: 'Other',
};

const useFormatLanguage = (audienceByLanguage = []) => {
  const formattedData = useMemo(() => {
    if (!audienceByLanguage || audienceByLanguage.length === 0) {
      return [];
    }

    const audienceObj = audienceByLanguage.reduce((acc, { name, count }) => {
      acc[name] = count;
      return acc;
    }, {});

    const total = Object.values(audienceObj).reduce(
      (sum, count) => sum + count,
      0
    );

    if (total === 0) return [];

    const languagesWithPercentage = Object.keys(audienceObj).map((lang) => ({
      group: languageNames[lang] || lang,
      value: audienceObj[lang],
      percentage: ((audienceObj[lang] * 100) / total).toFixed(2),
    }));

    return orderBy(languagesWithPercentage, ['percentage'], ['desc']);
  }, [audienceByLanguage]);

  return formattedData;
};

export default useFormatLanguage;
