import { useMemo } from 'react';
import orderBy from 'lodash/orderBy';

const useFormatEthnicity = (audienceByEthnicity = []) => {
  const formattedData = useMemo(() => {
    if (!audienceByEthnicity || audienceByEthnicity.length === 0) {
      return [];
    }

    const audienceObj = audienceByEthnicity.reduce((acc, { name, count }) => {
      acc[name] = count;
      return acc;
    }, {});

    const total = Object.values(audienceObj).reduce(
      (sum, count) => sum + count,
      0
    );

    if (total === 0) return [];

    const ethnicitiesWithPercentage = Object.keys(audienceObj).map(
      (ethnicity) => ({
        group: ethnicity,
        value: audienceObj[ethnicity],
        percentage: ((audienceObj[ethnicity] * 100) / total).toFixed(2),
      })
    );

    return orderBy(ethnicitiesWithPercentage, ['percentage'], ['desc']);
  }, [audienceByEthnicity]);

  return formattedData;
};

export default useFormatEthnicity;
