import React, { useState } from 'react';
import clsx from 'clsx';
import { Element } from 'react-scroll';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  getFollowersGrowth,
  getInfluencerDetails,
  getInfluencerRank,
} from 'api/brand/search';
import {
  AudienceSection,
  BrandsSection,
  ContentSection,
  EngagementCard,
  InfFollowerGrowth,
  InfProfileDetailsNavbar,
  OverviewCard,
} from 'components/common/inf-profile';
import { formatNumber } from 'utils';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import {
  brandIcon,
  contentIcon,
  growthGrayIcon,
  heartGrayIcon,
  instaPost,
  instaReels,
  instaVideos,
  platformIcon,
  usersGrayIcon,
} from 'constants/images';
import useFormatInstaAgeGenderData from 'hooks/useFormatInstaAgeGenderData';
import useFromatInstaCountryData from 'hooks/useFromatInstaCountryData';
import useFormatInstaCityData from 'hooks/useFormatInstaCityData';
import useFormatEthnicity from 'hooks/useFormatEthnicity';
import useFormatLanguage from 'hooks/useFormatLanguage';
import { NoData } from 'components/common/NoData';
import BrandAffinitySection from './brandAffinity';
import FollowersTypeBrand from './followersType';

const InstagramProfile = () => {
  const [follersGrowthFilter, setFollersGrowthFilter] = useState(90);

  const { id: influencerId } = useParams();
  const { data: influencer } = useQuery({
    queryKey: ['brand-influencer-details', influencerId],
    queryFn: () => getInfluencerDetails({ influencerId }),
  });

  const { data: infleucnerRank } = useQuery({
    queryKey: ['brand-influencer-rank', influencerId],
    queryFn: () => getInfluencerRank({ influencerId }),
  });

  const { data: followerGrowthData, isLoading: isLoadingFollowersGrowth } =
    useQuery({
      queryKey: [
        'brand-influencer-follower-growth',
        influencerId,
        'instagram',
        follersGrowthFilter,
      ],
      queryFn: () => {
        const params = {
          influencer_id: influencerId,
          follower_growth_filter: follersGrowthFilter,
          platform: 'instagram',
        };
        return getFollowersGrowth(params);
      },
    });

  const { instagramProfile } = influencer || {};
  const {
    postsStats,
    videoStats,
    reelsStats,
    brandPosts,
    recentPosts,
    topPosts,
    hashtags,
    brands,
    audienceByAgeGroupAndGenderRaw,
    audienceByCountryRaw,
    audienceByCityRaw,
    avgEngagementRate,
    creatorperformanceStats,
    brandAffinity,
    audienceinterests,
    audienceTypeRaw,
    audiencelanguagesRaw,
    audienceEthnicitiesRaw,
  } = instagramProfile || {};

  const { avgReelsview } = instagramProfile || {};

  const { audienceByAgeGroup, audienceByGender } = useFormatInstaAgeGenderData(
    audienceByAgeGroupAndGenderRaw
  );
  const audienceByCountry = useFromatInstaCountryData(audienceByCountryRaw);
  const { audienceByCity } = useFormatInstaCityData(audienceByCityRaw);
  const audienceByLanguage = useFormatLanguage(audiencelanguagesRaw);
  const audienceByEthnicity = useFormatEthnicity(audienceEthnicitiesRaw);
  const formattedAvgEngagementRate = avgEngagementRate
    ? `${(avgEngagementRate * 100).toFixed(2)}%`
    : null;

  return (
    <section style={{ position: 'relative' }}>
      <InfProfileDetailsNavbar
        config={[
          {
            title: 'Overview',
            to: 'overview',
            icon: platformIcon,
            offset: -200,
          },
          { title: 'Engagement', to: 'engagement', icon: heartGrayIcon },
          { title: 'Audience', to: 'audience', icon: usersGrayIcon },
          { title: 'Growth', to: 'growth', icon: growthGrayIcon },
          { title: 'Content', to: 'content', icon: contentIcon },
          { title: 'Brands', to: 'brands', icon: brandIcon },
        ]}
      />
      {/* Overview Section */}
      {instagramProfile?.followers &&
        creatorperformanceStats?.total &&
        avgEngagementRate &&
        avgReelsview && (
          <Element className={styles.navContentSection} name="overview">
            <OverviewCard
              title="Followers"
              value={formatNumber(instagramProfile?.followers)}
            />
            <OverviewCard
              title="Total Posts"
              value={formatNumber(creatorperformanceStats?.total)}
            />
            <OverviewCard
              title="Engagement Rate"
              value={formattedAvgEngagementRate || null}
            />
            <OverviewCard
              title="Average Reels View"
              value={formatNumber(avgReelsview)}
            />
            <OverviewCard
              title="Likes per post"
              value={formatNumber(creatorperformanceStats?.avgLikes)}
            />
            <OverviewCard
              title="Comments per post"
              value={formatNumber(creatorperformanceStats?.avgComments)}
            />
          </Element>
        )}

      {/* Engagement Section */}
      {postsStats?.avgLikes && reelsStats?.avgLikes && (
        <Element className={styles.navCategory} name="engagement">
          <div className={styles.sectionHeader}>
            <img src={heartGrayIcon} alt="" />
            <h2 className={styles.contntTxt}>Engagements & Views</h2>
          </div>
          <div className={clsx(styles.sectionBody, styles.engamntBody)}>
            <EngagementCard
              icon={instaPost}
              title="Posts"
              items={[
                {
                  name: 'Avg. Likes',
                  value: formatNumber(postsStats?.avgLikes),
                },
                {
                  name: 'Avg. Comments',
                  value: formatNumber(postsStats?.avgComments),
                },
                {
                  name: 'Likes-Comments Ratio',
                  value: formatNumber(postsStats?.likeCommentRatio, {
                    maximumFractionDigits: 2,
                  }),
                },
                {
                  name: 'Engagement Rate',
                  value: `${formatNumber(
                    instagramProfile?.avgEngagementRate
                  )}%`,
                  info: 'engagement_rate = (total_likes + total_comments) / total_media',
                },
              ]}
            />
            <EngagementCard
              icon={instaReels}
              title="Reels"
              items={[
                {
                  name: 'Avg. Likes',
                  value: formatNumber(reelsStats?.avgLikes),
                },
                {
                  name: 'Avg. Comments',
                  value: formatNumber(reelsStats?.avgComments),
                },
                {
                  name: 'Likes-Comments Ratio',
                  value: formatNumber(reelsStats?.likeCommentRatio, {
                    maximumFractionDigits: 2,
                  }),
                },
              ]}
            />
          </div>
        </Element>
      )}

      {/* Audience Section */}
      {audienceByCity?.length > 0 &&
        audienceByCountry?.length > 0 &&
        audienceByAgeGroup?.length > 0 &&
        audienceByGender?.length > 0 &&
        audienceByLanguage?.length > 0 &&
        audienceByEthnicity?.length > 0 && (
          <AudienceSection
            audienceByCity={audienceByCity}
            audienceByCountry={audienceByCountry}
            audienceByAgeGroup={audienceByAgeGroup}
            audienceByGender={audienceByGender}
            audienceByLanguage={audienceByLanguage}
            audienceByEthnicity={audienceByEthnicity}
          />
        )}

      {/* Growth Section */}
      {followerGrowthData && (
        <Element name="growth">
          <InfFollowerGrowth
            data={followerGrowthData}
            isLoading={isLoadingFollowersGrowth}
            handleFilterChange={setFollersGrowthFilter}
            filter={follersGrowthFilter}
          />
        </Element>
      )}

      {/* Content Section */}
      {brandPosts?.length > 0 ||
      recentPosts?.length > 0 ||
      topPosts?.length > 0 ||
      hashtags?.length > 0 ? (
        <Element name="content">
          <ContentSection
            brandPosts={brandPosts}
            recentPosts={recentPosts}
            topPosts={topPosts}
            hashtags={hashtags}
          />
        </Element>
      ) : null}

      {/* Brand Affinity Section */}
      {brandAffinity?.length > 0 && audienceinterests?.length > 0 && (
        <Element name="interest">
          <BrandAffinitySection
            brandAffinity={brandAffinity}
            audienceinterests={audienceinterests}
          />
        </Element>
      )}

      {/* Followers Type Section */}
      {audienceTypeRaw?.length > 0 && (
        <Element name="Followestype">
          <FollowersTypeBrand audienceTypeRaw={audienceTypeRaw} />
        </Element>
      )}

      {/* Brands Section */}
      {brands?.length > 0 && (
        <Element name="brands">
          <BrandsSection
            brands={brands}
            platform="instagram"
            variant="brand"
            influencerId={influencerId}
          />
        </Element>
      )}

      {!instagramProfile?.isDataFetched && (
        <div className={styles.profileOverlay}>
          <NoData title="Profile is under review." description="" />
        </div>
      )}
    </section>
  );
};

export default InstagramProfile;
