import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Element } from 'react-scroll';
import {
  AudienceAgeBarChart,
  ProgressBar,
  TopAudience,
} from 'components/common/inf-profile';
import Tooltip from 'components/common/Tooltip';
import styles from 'assets/scss/pages/influencer-profile.module.scss';
import { usersGrayIcon } from 'constants/images';
import {
  Box,
  // Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

const AudienceSection = ({
  audienceByCity = [],
  audienceByCountry = [],
  audienceByGender = [],
  audienceByAgeGroup = [],
  audienceByLanguage = [],
  audienceByEthnicity = [],
}) => {
  const [selectedTab, setSelectedTab] = useState('cities');
  const [selectedAudienceTab, setSelectedAudienceTab] = useState('age');

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
  };

  const handleAudienceTabChange = (event, newValue) => {
    setSelectedAudienceTab(newValue);
  };

  const geographyDataToShow = useMemo(
    () =>
      selectedTab === 'cities'
        ? audienceByCity.slice(0, 10)
        : audienceByCountry.slice(0, 10),
    [selectedTab, audienceByCity, audienceByCountry]
  );

  const topGender = useMemo(
    () =>
      audienceByGender.reduce(
        (prev, current) =>
          prev && prev.percentage > current.percentage ? prev : current,
        {}
      ),
    [audienceByGender]
  );

  const topAgeGroup = useMemo(
    () =>
      audienceByAgeGroup.reduce(
        (prev, current) =>
          prev && prev.percentage > current.percentage ? prev : current,
        {}
      ),
    [audienceByAgeGroup]
  );

  const dataToShow = useMemo(() => {
    switch (selectedAudienceTab) {
      case 'age':
        return audienceByAgeGroup;
      case 'language':
        return audienceByLanguage;
      case 'ethnicity':
        return audienceByEthnicity;
      default:
        return audienceByAgeGroup;
    }
  }, [
    selectedAudienceTab,
    audienceByAgeGroup,
    audienceByLanguage,
    audienceByEthnicity,
  ]);

  const topCity = audienceByCity[0];
  const topCountry = audienceByCountry[0];

  return (
    <>
      <Element className={styles.navCategory} name="audience">
        <div className={styles.sectionHeader}>
          <img src={usersGrayIcon} alt="" />
          <h2 className={styles.contntTxt}>Audience</h2>
          <Tooltip label="Audience demographic & age distribution" />
        </div>
        <div className={clsx(styles.sectionBody, styles.overviewSection)}>
          {topCity && (
            <TopAudience
              title="TOP CITY"
              value={topCity?.name}
              desc={
                <>
                  Audience from {topCity?.name} is <b>{topCity?.percentage}%</b>
                </>
              }
            />
          )}
          {topCountry && (
            <TopAudience
              title="TOP COUNTRY"
              value={topCountry?.name}
              desc={
                <>
                  Audience from {topCountry?.name} is{' '}
                  <b>{topCountry?.percentage}%</b>
                </>
              }
            />
          )}
          {topGender ? (
            <TopAudience
              title="TOP GENDER"
              value={topGender?.gender}
              desc={
                <>
                  Total {topGender?.gender?.toLowerCase()} audience is{' '}
                  <b>{topGender?.percentage}%</b>
                </>
              }
            />
          ) : null}
        </div>
        {topAgeGroup ? (
          <div
            className={clsx(styles.sectionBody, styles.overviewSection)}
            style={{ gridTemplateColumns: '1.5fr 1fr' }}
          >
            <TopAudience
              title="TOP AGE GROUP"
              value={`${topAgeGroup.group} Years`}
              desc={
                <>
                  Total audience in this age group is{' '}
                  <b>{topAgeGroup.percentage}%</b>
                </>
              }
            />
          </div>
        ) : null}
      </Element>
      {geographyDataToShow.length > 0 && (
        <div className={styles.navCategory} style={{ marginTop: '25px' }}>
          <div className={styles.postHeader}>
            <div>
              <h2 className={styles.insightHeading}>AUDIENCE GEOGRAPHY</h2>
            </div>
            <div className={styles.growthFilter}>
              <ul className={styles.TimeBox}>
                <button
                  onClick={() => {
                    setSelectedTab('cities');
                  }}
                  type="button"
                  className={clsx(
                    styles.yearList,
                    selectedTab === 'cities' && styles.activeYearList
                  )}
                >
                  City
                </button>
                <button
                  onClick={() => {
                    setSelectedTab('country');
                  }}
                  type="button"
                  className={clsx(
                    styles.yearList,
                    selectedTab === 'country' && styles.activeYearList
                  )}
                >
                  Country
                </button>
              </ul>
            </div>
          </div>
          <div className={styles.profileInterestList}>
            {geographyDataToShow.map(({ name, percentage }) => (
              <ProgressBar label={name} value={percentage} key={name} />
            ))}
          </div>
        </div>
      )}
      {audienceByAgeGroup.length > 0 && (
        <>
          <Stack
            direction="row"
            gap={1}
            mt={3}
            mb={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography mb={2} mt={3}>
              AUDIENCE AGE GROUP
            </Typography>
            {dataToShow.length > 0 ? (
              <>
                <Tabs
                  scrollButtons="auto"
                  variant="standard"
                  aria-label="select audience type"
                  value={selectedAudienceTab}
                  onChange={handleAudienceTabChange}
                  className={styles.customTabs}
                  sx={{
                    '& .MuiTabs-indicator': {
                      display: 'none',
                      borderBottom: '1px solid #efeefe',
                    },
                  }}
                >
                  <Tab
                    label="Age"
                    disableRipple
                    value="age"
                    className={clsx(
                      styles.customTab,
                      selectedAudienceTab === 'age' && styles.customTabActive
                    )}
                  />
                  <Tab
                    label="Language"
                    disableRipple
                    value="language"
                    className={clsx(
                      styles.customTab,
                      selectedAudienceTab === 'language' &&
                        styles.customTabActive
                    )}
                  />
                  <Tab
                    label="Ethnicity"
                    disableRipple
                    value="ethnicity"
                    className={clsx(
                      styles.customTab,
                      selectedAudienceTab === 'ethnicity' &&
                        styles.customTabActive
                    )}
                  />
                </Tabs>
                <Box mt={1} mb={2}>
                  <AudienceAgeBarChart data={dataToShow} />
                </Box>
              </>
            ) : (
              <Typography
                variant="h6"
                align="center"
                color="textSecondary"
                mt={2}
              >
                No Data Available
              </Typography>
            )}
          </Stack>
          <Box mt={1} mb={2}>
            <AudienceAgeBarChart data={dataToShow} />
          </Box>
        </>
      )}
    </>
  );
};

AudienceSection.propTypes = {
  audienceByCity: PropTypes.array,
  audienceByCountry: PropTypes.array,
  audienceByGender: PropTypes.array,
  audienceByAgeGroup: PropTypes.array,
  audienceByLanguage: PropTypes.array,
  audienceByEthnicity: PropTypes.array,
};

export default AudienceSection;
