import { useTheme } from '@mui/material';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

const renderCustomizedLabelBarChart = (props) => {
  const { x, y, width, value } = props;
  const WIDTH = 50;
  const HEIGHT = 22;

  return (
    <g>
      <rect
        x={x - (WIDTH - width) / 2}
        y={y - 30}
        width={WIDTH}
        height={HEIGHT}
        stroke="#7C087F"
        fill="#fff"
      />
      <text
        x={x + width / 2}
        y={y - 18}
        fill="#3E4C59"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={12}
        fontWeight={600}
      >
        {value}%
      </text>
    </g>
  );
};

const AgeGroupBarChart = ({ data = [] }) => {
  const theme = useTheme();

  const topFiveData = data.slice(0, 5);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        width={500}
        height={270}
        data={topFiveData}
        margin={{
          top: 30,
          right: 0,
          left: 0,
          bottom: 5,
        }}
        barGap={2}
        barSize={32}
      >
        <CartesianGrid
          vertical={false}
          strokeWidth={1}
          stroke={theme.palette.grey[300]}
        />
        <XAxis
          dataKey="group"
          tickLine={false}
          axisLine={false}
          fontSize={13}
          stroke="#8f64ce"
        />
        <YAxis
          tickFormatter={(value) => `${value}%`}
          dataKey="percentage"
          tickLine={false}
          axisLine={false}
          fontSize={13}
          stroke="#8f64ce"
        />
        <Bar
          dataKey="percentage"
          fill="#FF00A2"
          radius={[4, 4, 0, 0]}
          isAnimationActive={false}
        >
          <LabelList content={renderCustomizedLabelBarChart} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AgeGroupBarChart;
